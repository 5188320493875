import Entypo from "@expo/vector-icons/build/Entypo";
import { StackActions, useFocusEffect, useNavigation } from "@react-navigation/native";
import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";

import CALENDAR from "../../../assets/icons/BASE/CALENDAR.svg";
import COMMENT from "../../../assets/icons/BASE/COMMENT.svg";
import GROUP from "../../../assets/icons/BASE/GROUP.svg";
import HOME from "../../../assets/icons/BASE/HOME.svg";
import OCCURENCES from "../../../assets/icons/BASE/OCCURENCES.svg";
import PHONE from "../../../assets/icons/BASE/PHONE.svg";
import RECEIPT from "../../../assets/icons/BASE/RECEIPT.svg";
import RESTAURATION_SERVICE from "../../../assets/icons/BASE/RESTAURATION_SERVICE.svg";
import SETTINGS from "../../../assets/icons/BASE/SETTINGS.svg";
import STAR from "../../../assets/icons/BASE/STAR.svg";
import Box from "../../components/Base/Box";
import { CustomText } from "../../components/Base/Text";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../components/Loader";
import SmsPackDashboard from "../../components/SmsPacks/SmsPackDashboard";
import { AppContext } from "../../contexts/AppContext";
import type { GetDefaultDashboardInformationFragment } from "../../graphql/generated/schema";
import {
  Modules,
  useGetDefaultDashboardInformationLazyQuery,
} from "../../graphql/generated/schema";
import type { AppStackParamList } from "../../navigation/AppStack/ParamList";
import { LINE_THICKNESS } from "../../theme";
import { PALETTE } from "../../theme/Palette";
import { compactNumber } from "../../utils/common";

const IMAGE_HEIGHT = 55;

const Dashboard = () => {
  const navigation = useNavigation();
  const { merchantPermissions } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [dashboardInfo, setDashboardInfo] =
    useState<GetDefaultDashboardInformationFragment | null>(null);

  const [getDashboardInfo] = useGetDefaultDashboardInformationLazyQuery();

  const goToSettings = () => {
    navigation.navigate("SETTINGS");
  };

  const handleGetDefaultInformation = async () => {
    try {
      const { data } = await getDashboardInfo({
        fetchPolicy: "network-only",
      });

      if (data?.getDefaultDashBoardInformation) {
        setDashboardInfo(data.getDefaultDashBoardInformation);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   handleGetDefaultInformation();
  // }, []);
  useFocusEffect(
    React.useCallback(() => {
      handleGetDefaultInformation();
    }, []),
  );

  if (loading) return <Loader />;

  if (!dashboardInfo)
    return (
      <Box>
        <ErrorMessage message="Une erreur est survenue." />
      </Box>
    );

  const handleNavigateToScreen = (
    screenName: keyof AppStackParamList,
    nestedScreenName: string,
  ) => {
    navigation.dispatch(
      StackActions.replace(screenName, {
        screen: nestedScreenName,
      }),
    );
  };

  const buildScrollableData = () => {
    const { totalMenus, totalPendingBookings, totalPendingOrders, totalPersons } =
      dashboardInfo;

    const isBookingEnabled =
      merchantPermissions.includes(Modules.All) ||
      merchantPermissions.includes(Modules.Reservation);

    const isOnlineSalesEnabled =
      merchantPermissions.includes(Modules.All) ||
      merchantPermissions.includes(Modules.OnlineSales);

    const data = [];

    // if (totalPendingBookings > 0) {
    if (isBookingEnabled) {
      data.push(
        <TouchableOpacity
          onPress={() => handleNavigateToScreen("ALERTS", "ALERTS_BOOKINGS_TAB")}
        >
          <Box
            flexDirection="row"
            alignItems="center"
            p="s"
            mr="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            <OCCURENCES height={24} width={24} fill={PALETTE.darkBlue} />
            <CustomText ml="s" variant="content" color="primaryTextColor">
              {totalPendingBookings} résa en attente
            </CustomText>
          </Box>
        </TouchableOpacity>,
      );
    }
    // }

    // if (totalPendingOrders > 0) {
    if (isOnlineSalesEnabled)
      data.push(
        <TouchableOpacity
          onPress={() => handleNavigateToScreen("ALERTS", "ALERTS_ORDER_TABS")}
        >
          <Box
            flexDirection="row"
            alignItems="center"
            p="s"
            mr="s"
            backgroundColor="disabled"
            borderRadius="button"
          >
            <RECEIPT height={24} width={24} fill={PALETTE.darkBlue} />
            <CustomText ml="s" variant="content" color="primaryTextColor">
              {totalPendingOrders} commandes en attente
            </CustomText>
          </Box>
        </TouchableOpacity>,
      );
    // }

    // if (totalPersons > 0) {
    data.push(
      <Box
        flexDirection="row"
        alignItems="center"
        p="s"
        mr="s"
        backgroundColor="disabled"
        borderRadius="button"
      >
        <RESTAURATION_SERVICE height={24} width={24} fill={PALETTE.darkBlue} />
        <CustomText ml="s" variant="content" color="primaryTextColor">
          {totalPersons} couverts
        </CustomText>
      </Box>,
    );
    // }

    // if (totalMenus > 0) {
    data.push(
      <Box
        flexDirection="row"
        alignItems="center"
        p="s"
        mr="s"
        backgroundColor="disabled"
        borderRadius="button"
      >
        <GROUP height={24} width={24} fill={PALETTE.darkBlue} />
        <CustomText ml="s" variant="content" color="primaryTextColor">
          {totalMenus} menus
        </CustomText>
      </Box>,
    );
    // }

    return data;
  };

  const displayRatingStars = () => {
    const stars = [];
    for (let i = 0; i < Math.ceil(dashboardInfo.merchantReviewRating || 0); i++) {
      stars.push(<Entypo name="star" size={15} color="gold" />);
    }
    return stars;
  };

  const displayDate = () => format(new Date(), "dd MMMM yyyy");
  return (
    <Box
      flex={1}
      backgroundColor="disabled"
      maxWidth={1024}
      width="100%"
      flexDirection="row"
      alignSelf="center"
    >
      <ScrollView
        style={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 50,
        }}
      >
        <Box>
          <Box
            p="s"
            paddingVertical="m"
            // mt="m"
            pb="l"
            backgroundColor="white"
          >
            <Box flexDirection="row" alignItems="center">
              {dashboardInfo.merchant.coverImage && (
                <Box>
                  <Image
                    source={{
                      uri: dashboardInfo.merchant.coverImage,
                    }}
                    style={{
                      width: IMAGE_HEIGHT,
                      height: IMAGE_HEIGHT,
                      borderRadius: IMAGE_HEIGHT / 2,
                    }}
                  />
                </Box>
              )}

              <Box ml="s" flex={1}>
                <Box
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box flexDirection="row" alignItems="center">
                      {displayRatingStars().map((star, index) => (
                        <Box key={index}>{star}</Box>
                      ))}
                    </Box>
                  </Box>
                  <Box flexDirection="row" alignItems="center">
                    <CALENDAR height={24} width={24} fill={PALETTE.darkBlue} />
                    <CustomText ml="s" variant="content" color="success">
                      {displayDate()}
                    </CustomText>
                  </Box>
                </Box>
                <Box>
                  <CustomText variant="title" color="primaryTextColor">
                    {dashboardInfo.merchant.label}
                  </CustomText>
                </Box>
                <Box
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CustomText variant="content" color="primaryTextColor">
                    {dashboardInfo.merchant.email}
                  </CustomText>
                  <CustomText variant="content" color="primaryTextColor">
                    {dashboardInfo.merchant.phone}
                  </CustomText>
                </Box>
              </Box>
            </Box>
            <Box
              // position="absolute"
              // bottom={-30}
              mt="m"
              ml="s"
            >
              <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                {buildScrollableData().map((item, idx) => {
                  return <Box key={idx}>{item}</Box>;
                })}
              </ScrollView>
            </Box>
          </Box>
        </Box>

        <Box
          paddingHorizontal="s"
          mt="m"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            flex={1}
            p="m"
            backgroundColor="white"
            borderRadius="button"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <CustomText variant="label" color="success">
                {dashboardInfo.merchantImpressionMarkers.totalViews} vues
              </CustomText>
            </Box>
            <Box flexDirection="row" alignItems="center">
              <Box mr="s" flexDirection="row" alignItems="center">
                <PHONE height={30} width={30} fill={PALETTE.darkBlue} />
                <Box
                  borderRadius="button"
                  backgroundColor="danger"
                  position="absolute"
                  top={-10}
                  right={-10}
                  width={20}
                  height={20}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CustomText variant="description" color="white">
                    {dashboardInfo.merchantImpressionMarkers.totalCalls}
                  </CustomText>
                </Box>
              </Box>
              <Box mr="s" flexDirection="row" alignItems="center">
                <HOME height={30} width={30} fill={PALETTE.darkBlue} />
                <Box
                  borderRadius="button"
                  backgroundColor="danger"
                  position="absolute"
                  top={-10}
                  right={-10}
                  width={20}
                  height={20}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CustomText variant="description" color="white">
                    {dashboardInfo.merchantImpressionMarkers.totalViews}
                  </CustomText>
                </Box>
              </Box>
              <Box mr="s" flexDirection="row" alignItems="center">
                <COMMENT height={30} width={30} fill={PALETTE.darkBlue} />
                <Box
                  borderRadius="button"
                  backgroundColor="danger"
                  position="absolute"
                  top={-10}
                  right={-10}
                  width={20}
                  height={20}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CustomText variant="description" color="white">
                    {dashboardInfo.merchantImpressionMarkers.totalMessages}
                  </CustomText>
                </Box>
              </Box>
            </Box>
          </Box>
          <TouchableOpacity onPress={goToSettings}>
            <Box ml="s" p="m" backgroundColor="white" borderRadius="button">
              <SETTINGS height={30} width={30} fill={PALETTE.darkBlue} />
            </Box>
          </TouchableOpacity>
        </Box>

        <Box
          flex={1}
          flexDirection={{
            phone: "column",
            tablet: "row",
          }}
          alignItems={{
            tablet: "center",
          }}
          mt={{
            phone: "none",
            tablet: "s",
          }}
        >
          <Box
            flex={1}
            height="100%"
            justifyContent={{
              tablet: "space-between",
            }}
            marginHorizontal="s"
            maxHeight={275}
            mt="m"
            backgroundColor="white"
            p="s"
            borderRadius="button"
          >
            <Box>
              <CustomText variant="title" color="primaryTextColor">
                Note globale
              </CustomText>

              <Box position="absolute" top={-20} right={5}>
                <STAR height={50} width={50} fill={PALETTE.darkBlue} />
              </Box>
            </Box>
            <Box flexDirection="row" alignItems="center">
              <Box flex={0.5}>
                <Box
                  width={50}
                  height={50}
                  backgroundColor="yellow"
                  p="s"
                  borderRadius="button"
                  alignItems="center"
                  justifyContent="center"
                >
                  <STAR height={30} width={30} fill={PALETTE.white} />
                </Box>
                <Box flexDirection="row" alignItems="center">
                  <CustomText fontSize={40} variant="label" color="primaryTextColor">
                    {compactNumber(dashboardInfo.merchantReviewRating, "fr")}
                  </CustomText>
                  <CustomText variant="paragraph" color="primaryTextColor">
                    /5
                  </CustomText>
                </Box>
              </Box>
              <Box flex={0.5}>
                <Box backgroundColor="disabled" p="s" borderRadius="button">
                  <CustomText variant="content" color="primaryTextColor">
                    Nombre de notes
                  </CustomText>
                  <CustomText variant="label" color="primaryTextColor">
                    {dashboardInfo.totalReviews}
                  </CustomText>
                </Box>
                <Box
                  mt="s"
                  backgroundColor="white"
                  borderColor="success"
                  borderWidth={LINE_THICKNESS}
                  p="s"
                  borderRadius="button"
                >
                  <CustomText variant="content" color="success">
                    Avis non répondus
                  </CustomText>
                  <CustomText variant="label" color="success">
                    {dashboardInfo.totalUnansweredReviews}
                  </CustomText>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box flex={1} height="100%" maxHeight={275}>
            <SmsPackDashboard />
          </Box>
        </Box>

        {/* <DashboardCalendar /> */}

        {/* <Box
          marginHorizontal="s"
          p="s"
          mt="m"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box width="45%" backgroundColor="white" borderRadius="button" p="s">
            <Box alignItems="center">
              <Box pt="s">
                <CustomText variant="title" color="primaryTextColor">
                  Vendredi 17
                </CustomText>
              </Box>
              <Box position="absolute" top={-20}>
                <Box mr="s" flexDirection="row" alignItems="center">
                  <HOME height={30} width={30} fill={PALETTE.darkBlue} />
                  <Box
                    borderRadius="button"
                    backgroundColor="danger"
                    p="xs"
                    position="absolute"
                    top={-10}
                    right={-5}
                  >
                    <CustomText variant="description" color="white">
                      10
                    </CustomText>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                p="s"
                borderRadius="button"
                backgroundColor="success"
                flexDirection="row"
                alignItems="flex-start"
              >
                <PHONE height={12} width={12} fill={PALETTE.darkBlue} />
                <Box>
                  <Box flexDirection="row" alignItems="center">
                    <CustomText ml="s" variant="content" color="primaryTextColor">
                      KHANANO J
                    </CustomText>
                    <CustomText ml="s" variant="content" color="primaryTextColor">
                      x6
                    </CustomText>
                  </Box>
                  <CustomText ml="s" variant="content" color="primaryTextColor">
                    11:30
                  </CustomText>
                </Box>
              </Box>
              <Box
                p="s"
                paddingVertical="m"
                borderLeftColor="success"
                borderLeftWidth={3}
                top={-5}
              >
                <CustomText ml="s" variant="content" color="lightGrey">
                  6 autres réservations
                </CustomText>
              </Box>
            </Box>
          </Box>


          <Box width="45%" backgroundColor="white" borderRadius="button" p="s">
            <Box alignItems="center">
              <Box pt="s">
                <CustomText variant="title" color="primaryTextColor">
                  Messages
                </CustomText>
              </Box>
              <Box position="absolute" top={-20}>
                <Box mr="s" flexDirection="row" alignItems="center">
                  <HOME height={30} width={30} fill={PALETTE.darkBlue} />
                  <Box
                    borderRadius="button"
                    backgroundColor="danger"
                    p="xs"
                    position="absolute"
                    top={-10}
                    right={-5}
                  >
                    <CustomText variant="description" color="white">
                      10
                    </CustomText>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                alignItems="center"
                p="m"
                borderRadius="button"
                backgroundColor="success"
              >
                <Box flexDirection="row" alignItems="center">
                  <Box p="xs" backgroundColor="danger" borderRadius="button" />
                  <CustomText ml="s" variant="content" color="primaryTextColor">
                    KHANANO J
                  </CustomText>
                </Box>

                <CustomText ml="s" variant="content" color="primaryTextColor">
                  Bonjour je ...
                </CustomText>
              </Box>
              <Box
                p="s"
                paddingVertical="m"
                borderLeftColor="success"
                borderLeftWidth={3}
                top={-5}
              >
                <CustomText ml="s" variant="content" color="lightGrey">
                  10 autres messages
                </CustomText>
              </Box>
            </Box>
          </Box>
        </Box> */}
      </ScrollView>
    </Box>
  );
};

export default Dashboard;
